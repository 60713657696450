import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import request from '~utils/request';
import {
  COMMENTS_GET,
  COMMENTS_ADD,
  COMMENTS_EDIT,
  COMMENTS_DELETE,
  COMMENTS_REPORT
} from '~hooks/useApi';

import { pushDataLayerEvent } from '~utils/data-layer';

import Divider from '~components/Divider';
import LoadingSpinner from '~components/LoadingSpinner';

import { getFreeUserCommentRedirectProps } from '~utils/required-subscription-plan';
import Comment from '../Comment';
import CommentsInputBox from '../CommentInputBox';

class Comments extends Component {
  state = {
    isLoading: true,
    comments: [],
    visibleCommentsCount: 10
  };

  componentDidMount() {
    this.refreshComments();
  }

  componentDidUpdate(prevProps) {
    const { videoId } = this.props;
    if (videoId !== prevProps.videoId) {
      this.refreshComments();
    }
  }

  handleCommentSubmit = ({ comment, threadId }) => {
    const {
      playlistId,
      playlistUid,
      videoId,
      isNotAllowedToComment,
      hasUserSufficientAccess,
      handleInsufficientAccess
    } = this.props;

    if (!hasUserSufficientAccess) {
      handleInsufficientAccess();
      return;
    }
    if (isNotAllowedToComment) {
      navigate('/subscribe', getFreeUserCommentRedirectProps());
      return;
    }

    const submitCommentToApi = () => {
      request(COMMENTS_ADD({ playlistId, playlistUid, videoId, comment, threadId }))
        .then(({ data }) => {
          this.setState({ comments: data, isLoading: false });
        })
        .then(() => {
          pushDataLayerEvent(threadId ? 'comment_reply' : 'comment_add');
        })
        .catch(error => {
          console.error(error); // eslint-disable-line
          this.setState({ isLoading: false });
        });
    };

    this.setState({ isLoading: true }, submitCommentToApi);
  };

  handleCommentEdit = ({ commentId, comment }) => {
    this.setState({ isLoading: true });
    const { playlistUid } = this.props;
    request(COMMENTS_EDIT({ commentId, playlistUid, comment }))
      .then(({ data }) => {
        this.setState({ comments: data, isLoading: false });
      })
      .then(() => {
        pushDataLayerEvent('comment_edit');
      })
      .catch(error => {
        console.error(error); // eslint-disable-line
        this.setState({ isLoading: false });
      });
  };

  handleReportSubmit = ({ commentId }) => {
    this.setState({ isLoading: true });
    request(COMMENTS_REPORT({ commentId }))
      .then(() => {
        // response is { success: true }
        this.setState({ isLoading: false });
      })
      .then(() => {
        pushDataLayerEvent('comment_report');
      })
      .catch(error => {
        console.error(error); // eslint-disable-line
        this.setState({ isLoading: false });
      });
  };

  handleDeleteSubmit = ({ commentId }) => {
    this.setState({ isLoading: true });
    request(COMMENTS_DELETE({ commentId }))
      .then(({ data }) => {
        this.setState({ comments: data, isLoading: false });
      })
      .then(() => {
        pushDataLayerEvent('comment_delete');
      })
      .catch(error => {
        console.error(error); // eslint-disable-line
        this.setState({ isLoading: false });
      });
  };

  handleCommentsCount = comments => {
    const repliesCount = comments
      .filter(item => !!item.replies)
      .map(comment => comment.replies.length)
      .reduce((a, b) => a + b, 0);

    const commentsCount = comments.length + repliesCount;
    return commentsCount;
  };

  loadMoreComments = () =>
    this.setState(({ visibleCommentsCount }) => ({
      visibleCommentsCount: visibleCommentsCount + 10
    }));

  refreshComments() {
    const { playlistId, videoId } = this.props;
    request(COMMENTS_GET({ playlistId, videoId }))
      .then(({ data }) => {
        this.setState({ comments: data, visibleCommentsCount: 10, isLoading: false });
      })
      .catch(error => {
        console.error(error); // eslint-disable-line
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { isLoading, comments = [], visibleCommentsCount } = this.state;
    const handlers = {
      handleCommentSubmit: this.handleCommentSubmit,
      handleCommentEdit: this.handleCommentEdit,
      handleReportSubmit: this.handleReportSubmit,
      handleDeleteSubmit: this.handleDeleteSubmit
    };
    return (
      <div>
        {isLoading && <LoadingSpinner />}

        {!isLoading && (
          <Box my={4}>
            <Typography variant="h6" component="h3" gutterBottom>
              {(comments && this.handleCommentsCount(comments)) || 'No'} Comments
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              Be kind. Ask questions. Discriminatory language, personal attacks, promotion,
              proselytizing and spam will be removed.
            </Typography>
          </Box>
        )}

        {!isLoading && (
          <Box>
            <CommentsInputBox onSubmit={this.handleCommentSubmit} />
            <Divider spacing={2} />
          </Box>
        )}

        <Box>
          {comments.slice(0, visibleCommentsCount).map(comment => {
            const replies = comment.replies || [];
            return (
              <React.Fragment key={comment.id}>
                <Comment comment={comment} {...handlers}>
                  {replies.map(reply => (
                    <Comment key={reply.id} comment={reply} {...handlers} />
                  ))}
                </Comment>
                <Divider spacing={0} />
              </React.Fragment>
            );
          })}
        </Box>

        {visibleCommentsCount < comments.length && (
          <Box>
            <Button onClick={this.loadMoreComments} size="large">
              Show more comments
            </Button>
          </Box>
        )}
      </div>
    );
  }
}

Comments.propTypes = {
  playlistId: PropTypes.string.isRequired,
  playlistUid: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
  isNotAllowedToComment: PropTypes.bool,
  hasUserSufficientAccess: PropTypes.bool,
  handleInsufficientAccess: PropTypes.func
};
Comments.defaultProps = {
  isNotAllowedToComment: true,
  hasUserSufficientAccess: true,
  handleInsufficientAccess: null
};

export default Comments;
